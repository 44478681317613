import Vue from 'vue'

const stripeToEuro = function (number) {
  let numberArr = Array.from(String(number), Number)
  if (number < 0) {
    numberArr.splice(0, 1, '-')
  }
  numberArr.splice(-2, 0, ',')

  return numberArr.join('') + ' €'
}

const toEuro = function (number) {
  return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ') + ' €'
}

const filters = {
  stripeToEuro,
  toEuro
}

//register the filters in Vue
Object.keys(filters).forEach((k) => Vue.filter(k, filters[k]))
