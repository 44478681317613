import '@/../node_modules/btb-common_styles/src/scss/knacss.scss'
import '@/assets/index.scss'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './services/filters'
import Vuelidate from 'vuelidate'
import VTooltip from 'v-tooltip'
import InputFacade from 'vue-input-facade'
import VueSVGIcon from 'vue-svgicon'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

if (
  process.env.NODE_ENV === 'staging' ||
  process.env.NODE_ENV === 'production'
) {
  Sentry.init({
    Vue,
    // debug: true,
    dsn: 'https://0a55963d7b7e4fd89d78332cb31c64a5@o1213499.ingest.sentry.io/6352733',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', process.env.VUE_APP_BACKEND_API, /^\//]
      })
    ],
    release: `signup@${process.env.VERSION}`,
    environment: process.env.NODE_ENV,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  })
}

Vue.prototype.$Sentry = Sentry

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')

Vue.use(Vuelidate)
Vue.use(InputFacade)
Vue.use(VueSVGIcon)
Vue.use(VTooltip, {
  defaultTrigger: 'hover click focus',
  defaultPlacement: 'auto',
  defaultOffset: 5
})

if (process.env.NODE_ENV === 'production') {
  Vue.config.devtools = false
  Vue.config.debug = false
  Vue.config.silent = true
  Vue.config.productionTip = false
}
