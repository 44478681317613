import * as Sentry from '@sentry/vue'
import router from './../router'
import * as signupsApi from './../services/signups.api.js'

export default {
  attachSubscriptionToCustomer: async (
    { state, commit },
    { customerUID = state.customer.uid, signup = state.signup } = {}
  ) => {
    try {
      const response = await signupsApi.attachSubscriptionToCustomer(
        customerUID,
        signup
      )
      commit('STORE_USER_SUBSCRIPTION', response.subscription)
      return response
    } catch (error) {
      state.stripeError = true
      throw error
    }
  },

  finalizeSignup: async ({ state, dispatch }) => {
    const response = await signupsApi.finalizeSignup(state.signup.uid)
    dispatch('storeCustomer', response)
  },

  getACustomersSubscriptionWithStripeData: async (
    { state, commit },
    customerUID
  ) => {
    const response = await signupsApi.getACustomersSubscriptionWithStripeData(
      customerUID,
      state.subscription.uid
    )
    if (response.subscription && response.payment) {
      commit('STORE_USER_SUBSCRIPTION', response.subscription)
      let signup = {
        productName: response.subscription.productName,
        promocodeCode: response.subscription.promocodeCode
      }
      commit('STORE_USER_SIGNUP', signup)
      return true
    } else {
      return false
    }
  },

  getProducts: async ({ commit }) => {
    const response = await signupsApi.getProducts()
    if (response && response.data) {
      commit('STORE_PRODUCTS', response.data)
    } else {
      Sentry.captureMessage(`[warn] /products returned empty`)
    }
  },

  getPromocodeByCode: async ({ state, commit }, code) => {
    try {
      const response = await signupsApi.searchPromocodeByCode(
        code,
        state.signup.productName
      )
      const promocodeDTO = {
        ...response.data.promocode,
        found: response.data.found
      }
      commit('STORE_PROMOCODE', promocodeDTO)
    } catch (error) {
      state.signup.promocodeCode = null
      commit('STORE_PROMOCODE')
    }
  },

  getSponsorByCode: async ({ state, commit }, code) => {
    try {
      const response = await signupsApi.searchSponsorByCode(code)
      const sponsorDTO = {
        ...response.data.sponsor,
        found: response.data.found
      }
      commit('STORE_SPONSOR', sponsorDTO)
    } catch (error) {
      state.signup.userSponsorCode = null
      commit('STORE_SPONSOR')
    }
  },

  getSignupByUID: async ({ state, dispatch }, uid) => {
    const response = await signupsApi.getSignupByUID(uid)
    state.isNewSignup = false
    dispatch('storeSignup', response)
  },

  getACustomersSubscription: async ({ commit }, uid) => {
    const response = await signupsApi.getACustomersSubscription(uid)
    if (response.size === 1) {
      commit('STORE_USER_SUBSCRIPTION', response[0])
      return true
    } else {
      return false
    }
  },

  storeIsFormLoading: ({ commit }, value) => {
    commit('STORE_IS_FORM_LOADING', value)
  },

  storeSubscriptionStatus: async ({ commit }, subscriptionStatus) => {
    commit('STORE_SUBSCRIPTION_STATUS', subscriptionStatus)
  },

  storeSignup: ({ commit }, signup) => {
    commit('STORE_USER_SIGNUP', signup)
  },

  storeCustomer: ({ commit }, customer) => {
    commit('STORE_CUSTOMER', customer)
  },

  submitSignup: async ({ state }) => {
    let response

    if (state.isNewSignup) {
      response = await signupsApi.createSignup(state.signup)
      state.signup.uid = response.uid
    } else {
      response = await signupsApi.editSignup(state.signup, state.signup.uid)
    }

    if (state.isNewSignup) {
      router.replace({
        name: 'signup',
        params: { signupUid: response.uid }
      })
    }

    state.isNewSignup = false
    return response
  }
}
