export function signupToDTO(signupDomain) {
  try {
    const DTO = {
      invoiceName: signupDomain.invoiceName,
      invoiceAddressLabel: signupDomain.invoiceAddressLabel,
      invoiceAddressCountryISOAlpha2Code:
        signupDomain.invoiceAddressCountryISOAlpha2Code,
      invoiceAddressCity: signupDomain.invoiceAddressCity,
      invoiceAddressLatitude: signupDomain.invoiceAddressLatitude,
      invoiceAddressLine1: signupDomain.invoiceAddressLine1,
      invoiceAddressLine2: signupDomain.invoiceAddressLine2,
      invoiceAddressLongitude: signupDomain.invoiceAddressLongitude,
      invoiceAddressState: signupDomain.invoiceAddressState,
      invoiceAddressZipcode: signupDomain.invoiceAddressZipcode,
      invoiceFreeText: signupDomain.invoiceFreeText,

      companyIsFetched: signupDomain.companyIsFetched,
      companyCustomName: signupDomain.companyCustomName,
      companyName: signupDomain.companyName,
      companyLastKnownNumberOfEmployees:
        signupDomain.companyLastKnownNumberOfEmployees,
      companyLastKnownRevenue: signupDomain.companyLastKnownRevenue,
      companyRegistrationNumber: signupDomain.companyRegistrationNumber
        ? signupDomain.companyRegistrationNumber.split(' ').join('')
        : signupDomain.companyRegistrationNumber,
      companyRegistrationDate: signupDomain.companyRegistrationDate,
      companyTaxIdentifier: signupDomain.companyTaxIdentifier,
      companyTaxIdentifierType: signupDomain.companyTaxIdentifierType,
      companyAddressLabel: signupDomain.companyAddressLabel,
      companyAddressCountryISOAlpha2Code:
        signupDomain.companyAddressCountryISOAlpha2Code,
      companyAddressLatitude: signupDomain.companyAddressLatitude,
      companyAddressLongitude: signupDomain.companyAddressLongitude,
      companyAddressLine1: signupDomain.companyAddressLine1,
      companyAddressLine2: signupDomain.companyAddressLine2,
      companyAddressState: signupDomain.companyAddressState,
      companyAddressZipcode: signupDomain.companyAddressZipcode,
      companyAddressCity: signupDomain.companyAddressCity,

      productName: signupDomain.productName,
      promocodeCode: signupDomain.promocodeCode,

      userPositionTypeInCompany: signupDomain.userPositionTypeInCompany,
      userEmail: signupDomain.userEmail,
      userLastName: signupDomain.userLastName,
      userFirstName: signupDomain.userFirstName,
      userPhoneNumber: signupDomain.userPhoneNumber
        ? signupDomain.userPhoneNumber.split(' ').join('')
        : signupDomain.userPhoneNumber,
      userPhonePrefix: `${signupDomain.userPhonePrefix}`,
      userSponsorCode: signupDomain.userSponsorCode,
      // userPassword: signupDomain.userPassword,
      legalPublicityRightsValidationDate:
        signupDomain.legalPublicityRightsValidationDate
          ? Math.floor(Date.now() / 1000)
          : undefined,
      legalGTOSValidationDate: signupDomain.legalGTOSValidationDate
        ? Math.floor(Date.now() / 1000)
        : undefined
    }

    return DTO
  } catch (error) {
    console.error(error)
    throw error
  }
}

export function signupToDomain(signupDTO) {
  const domain = {
    companyIsFetched: signupDTO.companyIsFetched,
    companyName: signupDTO.companyName,
    companyCustomName: signupDTO.companyCustomName,
    companyLastKnownNumberOfEmployees:
      signupDTO.companyLastKnownNumberOfEmployees,
    companyLastKnownRevenue: signupDTO.companyLastKnownRevenue,
    companyRegistrationNumber: signupDTO.companyRegistrationNumber,
    companyRegistrationDate: signupDTO.companyRegistrationDate,
    companyTerminationDate: signupDTO.companyTerminationDate,
    companyTaxIdentifier: signupDTO.companyTaxIdentifier,
    companyTaxIdentifierType: signupDTO.companyTaxIdentifierType,
    companyAddressLabel: signupDTO.companyAddressLabel,
    companyAddressCountryISOAlpha2Code:
      signupDTO.companyAddressCountryISOAlpha2Code,
    companyAddressLatitude: signupDTO.companyAddressLatitude,
    companyAddressLongitude: signupDTO.companyAddressLongitude,
    companyAddressLine1: signupDTO.companyAddressLine1,
    companyAddressLine2: signupDTO.companyAddressLine2,
    companyAddressState: signupDTO.companyAddressState,
    companyAddressZipcode: signupDTO.companyAddressZipcode,
    companyAddressCity: signupDTO.companyAddressCity,

    invoiceName: signupDTO.invoiceName,
    invoiceAddressLabel: signupDTO.invoiceAddressLabel,
    invoiceAddressCountryISOAlpha2Code:
      signupDTO.invoiceAddressCountryISOAlpha2Code,
    invoiceAddressLine1: signupDTO.invoiceAddressLine1,
    invoiceAddressLine2: signupDTO.invoiceAddressLine2,
    invoiceAddressCity: signupDTO.invoiceAddressCity,
    invoiceAddressState: signupDTO.invoiceAddressState,
    invoiceAddressZipcode: signupDTO.invoiceAddressZipcode,

    productName: signupDTO.productName,
    promocodeCode: signupDTO.promocodeCode,

    signupFinalizationDate: signupDTO.signupFinalizationDate,
    signupScheduledExpirationDate: signupDTO.signupScheduledExpirationDate,
    signupStartDate: signupDTO.signupStartDate,
    signupStatus: signupDTO.signupStatus,

    userPositionTypeInCompany: signupDTO.userPositionTypeInCompany,
    userEmail: signupDTO.userEmail,
    userLastName: signupDTO.userLastName,
    userFirstName: signupDTO.userFirstName,
    userPhoneNumber: signupDTO.userPhoneNumber,
    userPhonePrefix: signupDTO.userPhonePrefix,
    userSponsorCode: signupDTO.userSponsorCode,
    repeatUserEmail: signupDTO.userEmail,
    legalPublicityRightsValidationDate:
      signupDTO.legalPublicityRightsValidationDate ? true : false,
    legalGTOSValidationDate: signupDTO.legalGTOSValidationDate ? true : false,

    uid: signupDTO.uid,
    customerUid: signupDTO.customerUid
  }
  return domain
}

export function subscriptionToDomain(subscriptionDTO) {
  const domain = {
    uid: subscriptionDTO.uid,
    status: subscriptionDTO.status,
    productName: subscriptionDTO.productName,
    promocodeCode: subscriptionDTO.promocodeCode
  }
  return domain
}

export function paymentToDomain(paymentDTO) {
  const domain = {
    clientSecret: paymentDTO.clientSecret,
    invoice: paymentDTO.invoice,
    subscription: paymentDTO.subscription
  }
  return domain
}

export function customerToDomain(customerDTO) {
  const domain = {
    uid: customerDTO.uid
  }
  return domain
}
