import axios from 'axios'
import Vue from 'vue'
import router from './../../router'
import Toasted from 'vue-toasted'
Vue.use(Toasted, { iconPack: 'callback', duration: 5000 })
const signupServiceApiClient = axios.create({
  baseURL: `${process.env.VUE_APP_BACKEND_API}/v1`,
  headers: {
    'content-type': 'application/json'
  }
})
const toastedErrorOptions = {
  position: 'bottom-right',
  icon: (el) => {
    el.innerHTML =
      '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="mr-2" width="32" height="32" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="#fff" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path></svg>'
    return el
  },
  action: {
    text: 'Fermer',
    onClick: (e, toastObject) => {
      toastObject.goAway(0)
    }
  }
}
signupServiceApiClient.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    // Si c'est une erreur métier
    if (error.response && error.response.data && error.response.data.errors) {
      for (const singleError of error.response.data.errors) {
        Vue.toasted.error(singleError.message, toastedErrorOptions)
      }
      // transmission de l'erreur pour une potentielle gestion au niveau des vues
      throw error
    } else {
      if (error.message === 'Network Error') {
        router.replace({
          name: 'generic-error'
        })
      } else {
        Vue.toasted.error('Une erreur est survenue', toastedErrorOptions)
        throw error
      }
    }
  }
)

export { signupServiceApiClient }
