export default {
  STORE_IS_FORM_LOADING: (state, value) => {
    state.isFormLoading = value
  },
  STORE_USER_SIGNUP: (state, signup) => {
    state.signup = {
      ...state.signup,
      ...signup
    }
  },
  STORE_CUSTOMER: (state, customer) => {
    state.customer = { ...state.customer, ...customer }
  },
  STORE_USER_SUBSCRIPTION: (state, subscription) => {
    state.subscription = { ...state.subscription, ...subscription }
  },
  STORE_SUBSCRIPTION_STATUS: (state, subscriptionStatus) => {
    state.subscriptionStatus = subscriptionStatus
  },
  STORE_PRODUCTS: (state, productsResponse) => {
    state.products = productsResponse.products
    state.productsSize = productsResponse.size
  },
  STORE_PROMOCODE: (state, promocode) => {
    state.promocode = { ...promocode }
    if (promocode && promocode.code) {
      state.signup.promocodeCode = promocode.code
    } else {
      state.signup.promocodeCode = null
    }
  },
  STORE_SPONSOR: (state, sponsor) => {
    state.sponsor = { ...sponsor }
    if (sponsor && sponsor.code && sponsor.found) {
      state.signup.userSponsorCode = sponsor.code
    } else {
      state.signup.userSponsorCode = null
    }
  }
}
