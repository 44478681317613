<script>
const supportedBrowsers = require('./supportedBrowsers')

export default {
  created() {
    if (!supportedBrowsers.test(navigator.userAgent)) {
      this.$router.replace({ name: 'browsers' })
    }
  }
}
</script>

<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style lang="scss">
#app {
  min-height: 100vh;
}
.final-page {
  min-height: 100vh;
  text-align: center;
  padding: $spacer-small;
  > img {
    margin: $spacer-large 0;
  }
  h1 {
    margin-bottom: $spacer-medium;
  }
  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    padding: $spacer-small-plus;
  }
  @media screen and (max-width: 360px) {
    > img {
      margin: $spacer-small 0;
    }
  }
}
</style>
