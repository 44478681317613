export const signupRoutes = [
  {
    path: '/',
    component: () => import('@/views/form/signup-form.vue'),
    props: { step: 0 },
    meta: {
      title: 'Adhésion | Bouge ta Boite'
    }
  },
  {
    path: '/customers/:uid/payment',
    name: 'payment',
    component: () => import('@/views/form/signup-form.vue'),
    props: { step: 3 },
    meta: {
      title: 'Paiement | Bouge ta Boite'
    }
  },
  {
    path: '/signups/:signupUid',
    name: 'signup',
    component: () => import('@/views/form/signup-form.vue'),
    props: { step: 0 },
    meta: {
      title: 'Inscription | Bouge ta Boite'
    }
  },
  {
    path: '/success',
    name: 'success',
    component: () => import('@/views/form/success-page.vue'),
    meta: {
      title: 'Inscription réussie | Bouge ta Boite'
    }
  }
]
