import Vue from 'vue'
import VueRouter from 'vue-router'

import { signupRoutes } from './signup'

Vue.use(VueRouter)

const routes = [
  ...signupRoutes,
  {
    path: '/already-active',
    name: 'alreadyActive',
    component: () => import('@/views/form/success-page.vue'),
    meta: {
      title: 'Inscription déjà active | Bouge ta Boite'
    }
  },
  {
    path: '/error',
    name: 'generic-error',
    component: () => import('@/views/errors/generic-error.vue'),
    meta: {
      title: 'Erreur | Bouge ta Boite'
    }
  },
  {
    path: '/browsers',
    name: 'browsers',
    component: () => import('@/views/errors/browser-not-supported-error.vue'),
    meta: {
      title: "Votre navigateur n'est plus pris en charge | Bouge ta Boite"
    }
  },
  {
    path: '/*',
    component: () => import('@/views/errors/404-error.vue'),
    meta: {
      title: 'Erreur 404 | Bouge ta Boite'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

const DEFAULT_TITLE = 'Bouge ta Boite'
router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE
  })
})

export default router
