import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions.js'
import mutations from './mutations.js'
import getters from './getters.js'

import { assetsModule } from './assets.js'

Vue.use(Vuex)

export const initialState = () => {
  return {
    isFormLoading: false,
    isNewSignup: true,
    stripeError: false,
    signup: {
      uid: undefined,

      invoiceAddressLabel: undefined,
      invoiceAddressCountryISOAlpha2Code: 'FR',
      invoiceAddressCity: undefined,
      invoiceAddressLine1: undefined,
      invoiceAddressLine2: undefined,
      invoiceAddressZipcode: undefined,
      invoiceAddressState: undefined,

      companyIsFetched: undefined,
      companyCustomName: undefined,
      companyName: undefined,
      companyLastKnownNumberOfEmployees: undefined,
      companyLastKnownRevenue: null,
      companyRegistrationNumber: undefined,
      companyRegistrationDate: undefined,
      companyTaxIdentifier: undefined,
      companyTaxIdentifierType: 'eu_vat',
      companyAddressLabel: undefined,
      companyAddressCity: undefined,
      companyAddressLine1: undefined,
      companyAddressLine2: undefined,
      companyAddressZipcode: undefined,
      companyAddressCountryISOAlpha2Code: 'FR',
      companyAddressLatitude: undefined,
      companyAddressLongitude: undefined,
      companyAddressState: undefined,
      productName: undefined,
      promocodeCode: undefined,

      userPositionTypeInCompany: undefined,
      userEmail: undefined,
      userLastName: undefined,
      userFirstName: undefined,
      userPhoneNumber: undefined,
      userPhonePrefix: '33',
      userSponsorCode: undefined,
      legalPublicityRightsValidationDate: undefined,

      legalGTOSValidationDate: undefined,

      signupFinalizationDate: undefined,
      signupScheduledExpirationDate: undefined,
      signupStartDate: undefined,
      signupStatus: undefined
    },
    customer: {},
    subscription: {},
    products: undefined,
    productsSize: undefined,
    promocode: {
      found: false,
      isInvalid: false,
      isExpired: false
    },
    sponsor: {
      found: false,
      isInvalid: false
    }
  }
}

export default new Vuex.Store({
  modules: {
    assetsModule
  },
  actions: actions,
  mutations: mutations,
  getters: getters,
  state: initialState
})
