import { signupServiceApiClient } from './network'
import * as mappers from './mappers'
import * as Sentry from '@sentry/vue'

export async function attachSubscriptionToCustomer(uid, signup) {
  const response = await signupServiceApiClient({
    method: 'POST',
    url: `/customers/${uid}/subscriptions`,
    data: {
      productName: signup.productName,
      promocodeCode: signup.promocodeCode,
      category: 'stripe'
    }
  })
  const subscription = mappers.subscriptionToDomain(response.data.subscription)
  const payment = mappers.subscriptionToDomain(response.data.payment)
  return { subscription, payment }
}

export async function createSignup(signup) {
  const signupDTO = mappers.signupToDTO(signup)
  const response = await signupServiceApiClient({
    method: 'POST',
    url: '/signups',
    data: { ...signupDTO }
  })
  if (response.data && response.data.signup) {
    const domain = mappers.signupToDomain(response.data.signup)
    return domain
  } else {
    Sentry.captureMessage(`[warn] createSignup failed`)
  }
}

export async function editSignup(signup, uid) {
  const signupDTO = mappers.signupToDTO(signup)
  const response = await signupServiceApiClient({
    method: 'PATCH',
    url: `/signups/${uid}`,
    data: signupDTO
  })
  const domain = mappers.signupToDomain(response.data.signup)
  return domain
}

export async function finalizeSignup(uid) {
  const response = await signupServiceApiClient({
    method: 'POST',
    url: `/signups/${uid}/finalize`
  })
  const domain = mappers.customerToDomain(response.data.customer)
  return domain
}

export async function getACustomersSubscriptionWithStripeData(
  customerUID,
  subscriptionUID
) {
  const response = await signupServiceApiClient({
    method: 'GET',
    url: `/customers/${customerUID}/subscriptions/${subscriptionUID}?payment=true`
  })
  const subscription = mappers.subscriptionToDomain(response.data.subscription)
  const payment = mappers.paymentToDomain(response.data.payment)
  return { subscription, payment }
}

export async function getSignupByUID(uid) {
  const response = await signupServiceApiClient({
    method: 'GET',
    url: `/signups/${uid}`
  })
  const domain = mappers.signupToDomain(response.data.signup)
  return domain
}

export async function getACustomersSubscription(uid) {
  const response = await signupServiceApiClient({
    method: 'GET',
    url: `/customers/${uid}/subscriptions?limit=1`
  })
  const subscription = mappers.subscriptionToDomain(
    response.data.subscriptions[0]
  )
  return subscription
}

export async function getProducts() {
  const response = await signupServiceApiClient({
    method: 'GET',
    url: `/products`
  })
  return response
}

export async function searchPromocodeByCode(code, productName) {
  const response = await signupServiceApiClient({
    method: 'POST',
    url: `/promocodes/searches`,
    data: {
      code,
      productName
    }
  })
  return response
}

export async function searchSponsorByCode(code) {
  const response = await signupServiceApiClient({
    method: 'POST',
    url: `/sponsors/searches`,
    data: {
      code
    }
  })
  return response
}
